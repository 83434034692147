import { Tenant, TenantRow } from "../types/redux-types";

export default function (props: {
    tenantRow: TenantRow,
    hasTsgSupport: boolean,
}): Tenant {
    const { tenantRow, hasTsgSupport } = props;
    return {
        id: hasTsgSupport ? tenantRow.tenant_service_group : tenantRow.tenant_id,
        name: tenantRow.tenant_name,
        displayName: tenantRow.tenant_name,
        tsgId: tenantRow.tenant_service_group,
        gcsTenantId: tenantRow.gcs_tenant_id,
        cdlTenantId: tenantRow.tenant_id,
        platformType: tenantRow.platform_type,
        cyrEnv: tenantRow?.cyr_env,
    }
}
